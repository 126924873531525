<template>
  <div class="flex relative h-full min-h-0 flex-col">
    <PanelSideComments
      v-if="expandedComments"
      :comments="comments"
      :users="users"
      show-navigation
      @close="$emit('close')"
      @go-back="expandedComments = false"
      @edit="$emit('edit', $event)"
      @delete="$emit('delete', $event)"
      @new-comment="$emit('new-comment', block && block.segmentId ? block.segmentId : 'document')"
    />
    <div v-else class="h-full flex flex-col gap-4 min-h-0 w-full py-4 px-4">
      <div v-if="editable && editor" class="flex gap-2 pb-3 flex-wrap w-full">
        <div class="w-full flex flex-col items-stretch">
          <fw-label>Estilo</fw-label>
          <b-dropdown
            class="w-full text-editor-styles"
            :scrollable="true"
            :disabled="!allowedStyling.includes(blockType)"
            :max-height="250"
            aria-role="list"
            position="is-bottom-right"
          >
            <template #trigger="{}">
              <fw-button class="mt-1 h-11" size="none" custom-class="pl-4 pr-2 py-0" expanded type="dropdownnopadding">
                <div class="flex-1 text-left" :class="[currentStyle.style]">{{ currentStyle.name }}</div>
                <fw-icon-chevron-down />
              </fw-button>
            </template>
            <b-dropdown-item
              v-for="(style, v) in styles"
              :key="'style_' + v"
              aria-role="listitem"
              @click="setStyle(style.style)"
            >
              <div :class="[style.style]">
                {{ style.name }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="w-full flex flex-col items-stretch">
          <fw-label>Formatação</fw-label>
          <div class="flex flex-wrap gap-2">
            <div class="inline-flex">
              <fw-button
                rounded="left"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive('bold') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                "
              >
                <fw-icon-bold class="h-5 w-5"></fw-icon-bold>
              </fw-button>
              <fw-button
                rounded="none"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive('italic') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                "
              >
                <fw-icon-italic class="h-5 w-5"></fw-icon-italic>
              </fw-button>
              <fw-button
                rounded="none"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive('underline') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleUnderline()
                    .run()
                "
              >
                <fw-icon-underline class="h-5 w-5"></fw-icon-underline>
              </fw-button>
              <fw-button
                rounded="right"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive('strike') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                "
              >
                <fw-icon-strike class="h-5 w-5"></fw-icon-strike>
              </fw-button>
            </div>
            <div class="inline-flex">
              <fw-button
                rounded="left"
                :disabled="!optionsActive"
                :type="editor && editor.isActive('superscript') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleSuperscript()
                    .run()
                "
              >
                <fw-icon-superscript class="h-5 w-5"></fw-icon-superscript>
              </fw-button>
              <fw-button
                rounded="right"
                :disabled="!optionsActive"
                :type="editor && editor.isActive('subscript') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleSubscript()
                    .run()
                "
              >
                <fw-icon-subscript class="h-5 w-5" />
              </fw-button>
            </div>
            <div class="inline-flex">
              <fw-button
                rounded="left"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive({ textAlign: 'left' }) ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('left')
                    .run()
                "
              >
                <fw-icon-align-left class="h-5 w-5" />
              </fw-button>
              <fw-button
                rounded="none"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive({ textAlign: 'center' }) ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('center')
                    .run()
                "
              >
                <fw-icon-align-center class="h-5 w-5" />
              </fw-button>
              <fw-button
                rounded="right"
                :disabled="!optionsActive && !titleOptionsActive"
                :type="editor && editor.isActive({ textAlign: 'right' }) ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .setTextAlign('right')
                    .run()
                "
              >
                <fw-icon-align-right class="h-5 w-5" />
              </fw-button>
            </div>
            <div class="inline-flex">
              <fw-button
                :rounded="editor.isActive('link') ? 'left' : 'full'"
                :disabled="!optionsActive"
                :type="editor && editor.isActive({ textAlign: 'link' }) ? 'light-primary' : 'xlight'"
                @click.native="setLink()"
              >
                <fw-icon-link class="h-5 w-5" />
              </fw-button>
              <fw-button
                v-if="editor && editor.isActive('link')"
                rounded="right"
                :type="editor && editor.isActive({ textAlign: 'link' }) ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .unsetLink()
                    .run()
                "
              >
                <fw-icon-unlink class="h-5 w-5" />
              </fw-button>
            </div>
            <div class="inline-flex gap-2">
              <fw-button
                :disabled="!optionsActive"
                :type="editor && editor.isActive('bulletList') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleBulletList()
                    .run()
                "
              >
                <fw-icon-unorderedlist class="h-5 w-5"></fw-icon-unorderedlist>
              </fw-button>
              <fw-button
                :disabled="!optionsActive"
                :type="editor && editor.isActive('orderedList') ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleOrderedList()
                    .run()
                "
              >
                <fw-icon-orderedlist class="h-5 w-5"></fw-icon-orderedlist>
              </fw-button>
              <fw-button
                :disabled="!optionsActive"
                :type="editor && editor.isActive({ textAlign: 'code' }) ? 'light-primary' : 'xlight'"
                @click.native="
                  editor
                    .chain()
                    .focus()
                    .toggleCode()
                    .run()
                "
              >
                <fw-icon-code class="h-5 w-5" />
              </fw-button>
            </div>
            <div class="inline-flex">
              <b-dropdown
                class="w-full"
                :disabled="!optionsActive"
                :max-height="250"
                aria-role="list"
                position="is-bottom-left"
              >
                <template #trigger="{}">
                  <fw-button :disabled="!optionsActive" :type="'xlight'">
                    <div
                      class="rounded-sm h-5 w-5 text-black/70 text-sm"
                      :class="{
                        'bg-white': !editor.isActive('highlight'),
                        'bg-[#ffff77]': editor.isActive('highlight', { color: '#ffff77' }),
                        'bg-[#aeff77]': editor.isActive('highlight', { color: '#aeff77' }),
                        'bg-[#7777ff]': editor.isActive('highlight', { color: '#7777ff' }),
                        'bg-[#ff7792]': editor.isActive('highlight', { color: '#ff7792' }),
                        'bg-[#ffae77]': editor.isActive('highlight', { color: '#ffae77' })
                      }"
                    >
                      A
                    </div>
                  </fw-button>
                </template>
                <div class="flex gap-1.5 p-1.5">
                  <button
                    :class="{ 'is-active': !editor.isActive('highlight') }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .unsetHighlight()
                        .run()
                    "
                  >
                    <div class="bg-white rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]">
                      A
                    </div>
                  </button>
                  <button
                    :class="{ 'is-active': editor.isActive('highlight', { color: '#ffff77' }) }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: '#ffff77' })
                        .run()
                    "
                  >
                    <div
                      class="rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]"
                      style="background-color: #ffff77"
                    >
                      A
                    </div>
                  </button>
                  <button
                    :class="{ 'is-active': editor.isActive('highlight', { color: '#aeff77' }) }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: '#aeff77' })
                        .run()
                    "
                  >
                    <div
                      class="rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]"
                      style="background-color: #aeff77"
                    >
                      A
                    </div>
                  </button>
                  <button
                    :class="{ 'is-active': editor.isActive('highlight', { color: '#7777ff' }) }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: '#7777ff' })
                        .run()
                    "
                  >
                    <div
                      class="rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]"
                      style="background-color: #7777ff"
                    >
                      A
                    </div>
                  </button>
                  <button
                    :class="{ 'is-active': editor.isActive('highlight', { color: '#ff7792' }) }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: '#ff7792' })
                        .run()
                    "
                  >
                    <div
                      class="rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]"
                      style="background-color: #ff7792"
                    >
                      A
                    </div>
                  </button>
                  <button
                    :class="{ 'is-active': editor.isActive('highlight', { color: '#ffae77' }) }"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setHighlight({ color: '#ffae77' })
                        .run()
                    "
                  >
                    <div
                      class="rounded h-7 w-7 text-black/70 text-sm border border-black/10 leading-[1.6rem]"
                      style="background-color: #ffae77"
                    >
                      A
                    </div>
                  </button>
                </div>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center gap-4 mb-1"
        :class="{
          'border-gray-300 border-t pt-5': editable && editor
        }"
      >
        <BlockIcon size="lg" class="text-gray-400" :type="blockType" />
        <div class="flex-1">
          <fw-heading size="h4">{{ blockLabels[blockType] }}</fw-heading>
        </div>
      </div>
      <div :key="localKey" class="comments-scroll-sidebar flex-1 overflow-hidden relative">
        <fw-heading size="label" :counter="comments.length">
          {{ blockType == 'document' ? $t('global_comments') : $t('block_comments') }}
        </fw-heading>
        <fw-button
          v-if="canCommentLocal && state != 'closed'"
          class="w-full my-3"
          type="dashed"
          @click.native="$emit('new-comment', block && block.segmentId ? block.segmentId : 'document')"
        >
          {{ $t('new_comment') }}
        </fw-button>
        <fw-message v-if="state == 'closed'" type="info" class="text-sm my-3">
          {{ $t('comments_closed') }}
        </fw-message>
        <div v-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center pt-4">
          <fw-icon-loading class="w-8 h-8" />
        </div>
        <div v-else-if="comments.length == 0" class="py-5 text-center text-sm text-gray-400 pt-4">
          {{ $t('no_comments') }}
        </div>
        <RecordCommentEditor
          v-for="comment in comments"
          :key="comment.key"
          minimal
          :post="comment"
          :users="users"
          class="mb-1.5"
          :type="comment.type"
          @edit="$emit('edit-comment', comment)"
          @delete="$emit('delete-comment', comment)"
          @open-comment="$emit('open-comment', comment)"
        />
        <div
          v-if="hasOverflow"
          class="absolute cursor-pointer bottom-0 left-0 text-xs font-semibold text-gray-500 pt-12 right-0 text-center pb-3 from-gray-50 via-gray-50 to-transparent bg-gradient-to-t"
          @click="expandedComments = true"
        >
          {{ $t('view_all_comments') }}
        </div>
      </div>
      <div v-if="false" class="flex flex-col gap-0.5 pt-4 text-gray-600">
        <fw-label>{{ $t('stats') }}</fw-label>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('words') }}</div>
          <div>{{ block && block.words ? block.words : '0' }}</div>
        </div>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('chars') }}</div>
          <div>{{ block && block.characters ? block.characters : '0' }}</div>
        </div>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('reading_time') }}</div>
          <div>{{ convertNumberOfWordsToTime(block ? block.words : null) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BlockIcon from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockIcon.vue'
import RecordCommentEditor from '@/fw-modules/fw-core-vue/pages/components/records/RecordCommentEditor.vue'
import PanelSideComments from '@/fw-modules/fw-core-vue/pages/components/panels/PanelSideComments.vue'

export default {
  name: 'SidebarBlockDetail',
  components: {
    BlockIcon,
    RecordCommentEditor,
    PanelSideComments
  },
  props: {
    block: {
      type: Object,
      default: () => null
    },
    comments: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object,
      default: () => ({})
    },
    myrole: {
      type: String,
      required: true
    },
    version: {
      type: Object,
      default: () => ({})
    },
    editor: {
      type: Object,
      default: () => null
    },
    editable: {
      type: Boolean,
      default: false
    },
    canComment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allowedStyling: ['paragraph', 'heading', 'codeBlock'],
      allowedBlocksFormatting: ['paragraph'],
      allowedTitleFormatting: ['heading'],
      blockLabels: {
        paragraph: this.$t('paragraph'),
        heading: this.$t('heading'),
        codeBlock: this.$t('codeblock'),
        document: this.$t('document')
      },
      hasOverflow: false,
      expandedComments: false,
      localKey: 0,
      limitedComments: [],
      styles: [
        {
          style: 'h1',
          name: 'Título'
        },
        {
          style: 'h2',
          name: 'Subtítulo'
        },
        {
          style: 'h3',
          name: 'Sub-subtítulo'
        },
        /*{
          style: 'meta',
          name: 'Metadados'
        },*/
        {
          style: 'paragraph',
          name: 'Parágrafo'
        },
        {
          style: 'codeblock',
          name: 'Código'
        }
      ]
    }
  },
  computed: {
    blockType() {
      return this.block ? this.block.type : 'document'
    },
    titleOptionsActive() {
      return this.editable && this.editor && this.allowedTitleFormatting.includes(this.blockType)
    },
    optionsActive() {
      return this.editable && this.editor && this.allowedBlocksFormatting.includes(this.blockType)
    },
    currentStyle() {
      //swith editor.isActive('heading', { level: 1 })
      if (this.editor && this.editor.isActive('heading', { level: 1 })) {
        return this.styles.find(style => style.style === 'h1')
      } else if (this.editor && this.editor.isActive('heading', { level: 2 })) {
        return this.styles.find(style => style.style === 'h2')
      } else if (this.editor && this.editor.isActive('heading', { level: 3 })) {
        return this.styles.find(style => style.style === 'h3')
      } else if (this.editor && this.editor.isActive('meta')) {
        return this.styles.find(style => style.style === 'meta')
      } else if (this.editor && this.editor.isActive('codeblock')) {
        return this.styles.find(style => style.style === 'codeblock')
      } else {
        return this.styles.find(style => style.style === 'paragraph')
      }
    },
    state() {
      return this.version ? this.version.state : null
    },
    canCommentLocal() {
      return (
        // (this.myrole === 'owner' ||
        //   this.myrole === 'manager' ||
        //   this.myrole === 'editor' ||
        //   this.myrole === 'reviewer') &&
        (this.blockType == 'document' || this.block) && this.canComment
      )
    }
  },
  watch: {
    block: {
      deep: true,
      //immediate: true,
      handler() {
        this.detectOverflow()
      }
    },
    comments: {
      deep: true,
      //immediate: true,
      handler() {
        //console.log('comments changed!!! =========', this.comments)
        //this.$forceUpdate()
        this.detectOverflow()
      }
    }
  },
  mounted() {
    this.detectOverflow()
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      this.$buefy.dialog.prompt({
        title: 'URL',
        //optional
        inputAttrs: {
          value: previousUrl,
          placeholder: 'URL',
          required: false
        },
        confirmText: 'Guardar',
        cancelText: 'Cancelar',
        onConfirm: url => {
          // cancelled
          if (url === null) {
            return
          }
          if (url === '') {
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .unsetLink()
              .run()
          } else {
            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .run()
          }
        }
      })
    },
    setStyle(style) {
      if (this.editor) {
        if (style == 'h1' || style == 'h2' || style == 'h3') {
          let level = parseInt(style.replace('h', ''))
          this.editor
            .chain()
            .focus()
            .setHeading({ level: level })
            .run()
          return
        } else if (style == 'paragraph') {
          this.editor
            .chain()
            .focus()
            .setParagraph()
            .run()
          return
        } else if (style == 'codeblock') {
          this.editor
            .chain()
            .focus()
            .setCodeBlock()
            .run()
          return
        } else if (style == 'meta') {
          this.editor
            .chain()
            .focus()
            .setMeta()
            .run()
          return
        }
      }
    },
    detectOverflow() {
      //this.limitedComments = this.comments.slice(0, 5)
      Vue.nextTick(() => {
        //detect if there are more comments than space available
        let element = document.querySelector('.comments-scroll-sidebar')
        if (element) {
          this.hasOverflow = element.scrollHeight > element.clientHeight
        } else {
          this.hasOverflow = false
        }
      })
    },
    convertNumberOfWordsToTime(numberOfWords) {
      if (!numberOfWords) return '0m 0s'
      let minutes = Math.floor(numberOfWords / 110)
      let seconds = Math.ceil((numberOfWords % 110) / (110 / 60))
      return `${minutes}m ${seconds}s`
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "block_details": "Detalhes do bloco",
      "document_details": "Detalhes da página",
      "comments": "Comentários",
      "block_comments": "Comentários deste bloco",
      "global_comments": "Comentários gerais",
      "new_comment": "Novo comentário",
      "no_comments": "Sem comentários",
      "view_all_comments": "Ver todos os comentários",
      "stats": "Estatísticas",
      "words": "Palavras",
      "chars": "Caracteres",
      "reading_time": "Tempo de leitura",
      "comments_closed": "Novos comentários estão bloqueados para esta versão",
      "paragraph": "Parágrafo",
      "heading": "Título",
      "codeblock": "Código",
      "document": "Documento"
    },
    "en": {
      "block_details": "Block details",
      "global_comments": "General comments",
      "document_details": "Page details",
      "comments": "Comments",
      "block_comments": "Comments for this block",
      "new_comment": "New comment",
      "no_comments": "No comments",
      "view_all_comments": "View all comments",
      "stats": "Stats",
      "words": "Words",
      "chars": "Characters",
      "reading_time": "Reading time",
      "comments_closed": "New comments are blocked for this version",
      "paragraph": "Paragraph",
      "heading": "Heading",
      "codeblock": "Code",
      "document": "Document"
    }
  }
</i18n>

<style scoped>
.text-editor-styles .h1 {
  font-size: 1.5rem;
  font-weight: 700;
}
.text-editor-styles .h2 {
  font-size: 1.25rem;
  font-weight: 700;
}
.text-editor-styles .h3 {
  font-size: 1rem;
  font-weight: 700;
}
.text-editor-styles .meta {
  font-size: 0.75rem;
  font-weight: 700;
  color: #616161;
}
.text-editor-styles .paragraph {
  font-size: 1rem;
  font-weight: 400;
}
.text-editor-styles .codeblock {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  font-weight: 400;
  border-radius: 0.5rem;
  line-height: 2rem;
  padding: 0rem 0.5rem;
}
</style>
